import { Children, cloneElement, createRef, useRef, useState } from "react"
import styled from "styled-components"

import { Tooltip } from "@material-ui/core"
import { useSnackbar } from "notistack"

import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { palette } from "src/ui/colors"
import EditIcon from "src/ui/icons/edit.svg"
import { ErrorService } from "src/utils/ErrorService"

/**
 * This wrapper takes a single HTML element and makes it editable by applying
 * the contenteditable attribute to it. It should work for, e.g., h1-h6, divs,
 * spans, p, &etc, but don't try anything too fancy.
 */
export function Editable({
  onSave,
  children,
  multiline = false,
  disabled = false,
  ...props
}: {
  onSave: (text: string) => void | Promise<void>
  children?: React.ReactElement | string
  multiline?: boolean
  disabled?: boolean
}) {
  const [oldText, setOldText] = useState("")
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const ref = useRef<HTMLElement | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  const notistackRef = createRef<any>()
  const [editing, setEditing] = useState(false)
  const { t } = useTranslate()

  async function save(text: string | undefined | null, snackBar = 1) {
    if (!text) {
      if (ref.current) ref.current.textContent = oldText
      return
    }
    if (text === oldText) {
      return // no need to send a request
    }
    try {
      await onSave(text)
    } catch (error) {
      enqueueSnackbar(t(langKeys.failed_to_save), {
        ref: notistackRef,
        key: snackBar,
        variant: "error",
        action: (key) => (
          <ErrorActions
            onDismiss={() => closeSnackbar(key)}
            onRetry={() => save(text, snackBar + 1)}
          />
        ),
      })
      ErrorService.captureException(error)
    }
  }

  if (disabled) {
    return <>{children}</>
  }

  const clones = Children.map(children, (child) => {
    const childElem = typeof child === "string" ? <div>{child}</div> : child
    if (!childElem) {
      return null
    }
    return cloneElement(childElem, {
      contentEditable: true,
      suppressContentEditableWarning: true,
      ref: ref,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
      onFocus: (e: any) => {
        setEditing(true)
        const text = ref.current?.textContent
        !!text && setOldText(text)
      },
      onBlur: (e: FocusEvent) => {
        const text = ref.current?.textContent
        save(text)
        setEditing(false)
      },
      onKeyDown: (e: KeyboardEvent) => {
        switch (e.key) {
          case "Enter":
            if (!multiline) {
              e.preventDefault()
              ref.current?.blur()
            }
            return
          case "Escape": // NOTE: Capturing Escape doesn't work in Firefox
            if (ref.current) {
              ref.current.textContent = oldText
              ref.current.blur()
            }
            return

          default:
            return
        }
      },
    })
  })

  function onPenClick() {
    if (!ref.current) return
    ref.current?.focus()
    const range = document.createRange()
    range.selectNodeContents(ref.current)
    range.collapse()
    const sel = window.getSelection()
    sel?.removeAllRanges()
    sel?.addRange(range)
  }

  const clone = clones?.[0]
  if (!clone) {
    return null
  }

  return (
    <section style={{ display: "inline-flex" }} translate="no">
      <Tooltip title={t(langKeys.change_name) || ""} placement="bottom">
        <Wrapper>{clone}</Wrapper>
      </Tooltip>

      <EditWrapper
        onClick={onPenClick}
        visibility={editing ? "hidden" : "visible"}
      >
        <EditIcon width="100%" height="100%" />
      </EditWrapper>
    </section>
  )
}

const Wrapper = styled.div<{ borderWidth?: string; sidePadding?: string }>`
  --border-width: ${(props) => props.borderWidth || "2px"};
  --padding-sides: ${(props) => props.sidePadding || "0.1em"};

  display: inline-flex;

  &:focus-within,
  &:hover {
    cursor: text;
    /* Add spacing between border and wrapped component: */
    padding: 0px var(--padding-sides);

    /* Negative margins so that spacing does not affect layout: */
    margin: calc(-1 * var(--border-width))
      calc(-1 * (var(--padding-sides) + var(--border-width)));
  }
  &:hover {
    border: var(--border-width) solid #0003;
  }
  &:focus-within {
    border: var(--border-width) solid ${palette.hav};
  }

  & > * {
    &:focus-within {
      outline: none; /* hide default focus outline in firefox */
    }
  }
`

function ErrorActions({
  onDismiss,
  onRetry,
}: {
  onDismiss: () => void
  onRetry: () => void
}) {
  return (
    <ActionButtons>
      <MButtonLegacy
        variant="text"
        color="secondary"
        onClick={() => onDismiss()}
      >
        Dismiss
      </MButtonLegacy>
      <MButtonLegacy variant="text" color="secondary" onClick={() => onRetry()}>
        Retry
      </MButtonLegacy>
    </ActionButtons>
  )
}

const ActionButtons = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto auto;
`

const EditWrapper = styled.div<{ visibility?: "hidden" | "visible" }>`
  flex-shrink: 0;
  width: 0.5em;
  opacity: 0.75;
  margin-left: 0.25em;
  visibility: ${(props) => props.visibility || "visible"};
  cursor: pointer;
  display: inline-block;
`
